* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  
}

body{
  background: url('./components/CardEvent/eventbackdrop.jpg');
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}