.sector_wrapper {
    border-top: 0px solid transparent;
    max-width: 20rem;
    max-height: 27rem;
    width: 20rem;
    height: 27rem;
    background-color: #08678C;
    color: white;
    padding: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    margin: 10px;
    transition: 0.2s all ease-in-out;
}

.sector_wrapper:hover {
    border-top: 5px solid rgba(255, 255, 255, 0.4);
    border-bottom: 5px solid rgba(255, 255, 255, 0.4);

    transition: 0.2s all ease-in-out;
}

.text_wrapper_sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #08678C;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 10vh;
    border-radius: 1rem;
    border-top: 3px solid rgba(255, 255, 255, 0.4)
}

.text_section_bottom {
    color: whitesmoke;
    font-size: 1.4rem;
    text-align: center;
    padding: 2rem;

}

.sector_image img {

    height: 100%;
    width: 100%;
    object-fit: cover;
    max-height: 20rem;
    border-top: 5px solid #023059;
    border-bottom: 5px solid #023059;
    height: 15rem;
    width: 18rem;
    border-radius: 0.8rem;
    transition: 0.2s all ease-in-out;
    image-rendering: optimizeSpeed;

}



.title_sector {
    font-size: 1.5rem;
    padding-top: 2vh;
    align-items: center;
    text-align: center;
}

.title_sector_main {
    font-size: 1.5rem;
    color: whitesmoke;
    width: fit-content;
   
    text-align: center;
    background: #08678C;
    border-radius: 1rem;

    max-width: 90vw;
    margin-bottom: 2vh;
}

.sector_cards_display_2 {
    background-color: #08678C;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    justify-self: center;
    display: flex;
    flex-direction: column;

}

.parent_sectors_main {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;

}

.sector_cards_display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    border-radius: 1rem;

    justify-self: center;
    margin-bottom: 5vh;
    width: 90%;



}

.sector_image {
    position: relative;

}

.text_area {
    padding-top: 1rem;
    font-size: 1.5rem;
}

@media screen and (max-width: 960px) {


    .title_sector_main {
        font-size: 1rem;
        width: 90vw;
        max-width: 90vw;
    }
    .sector_cards_display_2 {
        width: 90vw;
        max-width: 90vw;
    }

}